import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import Markdown from 'react-markdown';
import Title from 'components/Heading/SubHeading';
import Title2 from 'components/Heading/SubHeading2';
import Paragraph from 'components/Paragraph';
import SmallParagraph from 'components/Paragraph/Small';
import List from 'components/ItemList';
import SimpleList from 'components/ItemList/Simple';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.article`
  & + & {
    margin-top: 80px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    &:first-child {
      padding-top: 105px;
    }
    & + & {
      margin-top: 100px;
    }
  }
`;

const Description = styled(Paragraph)``;

const SmallDescription = styled(SmallParagraph)`
  margin-bottom: 30px;
`;

const SubParagraph = styled.article`
  & + & {
    margin-top: 30px;
  }
`;

const SubTitle = styled(Title2)``;
const StyledList = styled(List)`
  margin-top: 50px;
`;
const StyledSimpleList = styled(SimpleList)`
  margin-top: 50px;
`;

function TrustScoreSubSection({
  className,
  title,
  description,
  smallDescription,
  list,
  simpleList,
  paragraphs,
  id,
}) {
  return (
    <Wrapper
      className={classNames(className, 'sub-section', `sub-section-${id}`)}
    >
      <Title>{title}</Title>
      {description && <Description as={Markdown} source={description} />}
      {smallDescription && (
        <SmallDescription>{smallDescription}</SmallDescription>
      )}
      {list && <StyledList items={list} />}
      {simpleList && <StyledSimpleList items={simpleList} />}
      {paragraphs &&
        paragraphs.map(paragraph => (
          <SubParagraph key={paragraph.title}>
            <SubTitle>{paragraph.title}</SubTitle>
            <SmallParagraph>{paragraph.description}</SmallParagraph>
          </SubParagraph>
        ))}
    </Wrapper>
  );
}

TrustScoreSubSection.defaultProps = {
  className: null,
  description: null,
  list: null,
  simpleList: null,
  paragraphs: null,
  smallDescription: null,
};

TrustScoreSubSection.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  smallDescription: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      iconType: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  simpleList: PropTypes.arrayOf(
    PropTypes.shape({
      iconType: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  paragraphs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ),
};

export default TrustScoreSubSection;
