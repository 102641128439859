import styled from 'styled-components';
import getIn from 'lodash/get';

export default styled.h5`
  color: ${props => getIn(props, 'theme.heading.textColor', '#1c2f45')};
  font-family: Helvetica;
  font-size: 1.13rem;
  font-weight: bold;
  line-height: 1.63rem;
  margin: 0.5rem 0;
`;
