import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Container, Visible } from 'react-grid-system';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import Img from 'components/Img';
import Heading from 'components/Heading';
import Button from 'components/Button';
import StickyPanel from 'components/StickyPanel';
import Paragraph from 'components/Paragraph';
import Spacer from 'components/Spacer';
import SubSection from './SubSection';

const Wrapper = styled.section`
  padding: 70px 0;
  background: #fff;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    text-align: left;
  }

  &.blue {
    background: #f4f9ff;
  }
`;

const StyledImg = styled(Img)`
  height: 207px;
  width: 207px;
  margin-bottom: 40px;
`;

function Section({ className, title, description, cta, sections, image, id }) {
  return (
    <Wrapper className={className} id={id}>
      <Container>
        <Row justify="between">
          <Col md={5}>
            <StickyPanel top="25%">
              <Heading title={title} />
              {description && <Paragraph>{description}</Paragraph>}
              {cta && (
                <Button title={cta.label} href={cta.link} as="a">
                  {cta.label}
                </Button>
              )}
            </StickyPanel>
            <Visible sm xs>
              <Spacer space="40px" />
            </Visible>
          </Col>
          <Col md={6}>
            {image && <StyledImg alt={title} publicURL={image} />}
            {sections.map(section => (
              <SubSection key={section.id} {...section} />
            ))}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

Section.defaultProps = {
  className: null,
  image: null,
  description: null,
  cta: null,
};

Section.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  cta: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
  }),
  sections: PropTypes.arrayOf(PropTypes.shape(SubSection.propTypes)).isRequired,
};

export default Section;
