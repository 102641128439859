import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from 'react-grid-system';
import classNames from 'classnames';
import styled, { ThemeProvider } from 'styled-components';
import Layout from 'components/Layout';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import Section from 'components/Products/TrustScore/Section';
import { HEADER_HEIGHT } from 'components/Header';
import breakpoints from 'utils/breakpoints';
import { scrollTo } from 'utils';
import * as themes from 'themes';

const Intro = styled.section`
  padding-top: ${HEADER_HEIGHT + 40}px;
  padding-bottom: 40px;
  background: #000;
  text-align: center;
  color: #fff;
  height: 385px;
  box-sizing: border-box;
  position: relative;

  ${Paragraph} {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
  }

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${HEADER_HEIGHT + 55}px;
    padding-bottom: 80px;
    position: relative;
  }
`;

const Tiles = styled.ul`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: auto;
  display: inline-flex;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(218, 218, 218, 0.4);
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media (min-width: ${breakpoints.md}) {
  }
`;

const Tile = styled.li`
  padding: 20px 25px;
  flex: 1;
  margin: 0;
  color: #1c2f45;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  transition: color 200ms linear;

  &:hover {
    color: #0276f1;
  }

  & + & {
    position: relative;
    &:before {
      content: '';
      width: 1px;
      height: 50px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      background: #dbe6f3;
      transform: translate(-50%, -50%);
    }
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 24px;
    line-height: 29px;
    padding: 25px 50px;
  }
`;

function TrustScoreProductPage({ className }) {
  const data = useStaticQuery(graphql`
    {
      allTrustScoreJson(sort: { fields: title }) {
        nodes {
          id
          title
          isIntroSection
          description
          cta {
            link
            label
          }
          sections {
            title
            id
            description
            list {
              title
              description
              iconType
            }
            simpleList {
              description
              iconType
            }
            paragraphs {
              title
              description
            }
          }
        }
      }
    }
  `);

  const { contentSections, introSection } = groupBy(
    data.allTrustScoreJson.nodes,
    node => {
      return node.isIntroSection ? 'introSection' : 'contentSections';
    }
  );

  const handleClickTile = sectionIndex => {
    scrollTo(`#trust-score-section-${sectionIndex}`, document.body);
  };

  return (
    <Layout
      headerTheme={themes.dark}
      className={className}
      fullSize
      overflow
      pageTitle="Trust Score"
    >
      {introSection.map(section => (
        <ThemeProvider theme={themes.light} key={section.title}>
          <Intro>
            <Container>
              <Heading title={section.title} />
              <Paragraph>{section.description}</Paragraph>
            </Container>
            <Tiles>
              {contentSections.map(item => (
                <Tile onClick={() => handleClickTile(item.id)} key={item.title}>
                  {item.title}
                </Tile>
              ))}
            </Tiles>
          </Intro>
        </ThemeProvider>
      ))}
      {contentSections.map((section, i) => (
        <Section
          className={classNames({ blue: i % 2 === 0 })}
          key={section.title}
          {...section}
          id={`trust-score-section-${section.id}`}
        />
      ))}
    </Layout>
  );
}

TrustScoreProductPage.defaultProps = {
  className: null,
};

TrustScoreProductPage.propTypes = {
  className: PropTypes.string,
};

export default TrustScoreProductPage;
