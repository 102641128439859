import styled from 'styled-components';
import getIn from 'lodash/get';

const Paragraph = styled.p`
  margin: 0;
  color: ${props => getIn(props, 'theme.paragraph.textColor', '#1c2f45')};
  letter-spacing: 0;
  font-size: 0.88rem;
  line-height: 1.38rem;
`;

export default Paragraph;
