import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { useHeaderPinnedState } from 'components/Header/headerPinnedContext';
import { HEADER_HEIGHT } from 'components/Header';

const Wrapper = styled.div`
  transition: 0.3s;

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 2rem;
    position: sticky;
    top: ${({ headerPinned, top }) =>
      top || `${headerPinned ? HEADER_HEIGHT + 32 : 32}px`};
  }
`;

function StickyPanel({ children, top, ...props }) {
  const headerPinned = useHeaderPinnedState();

  return (
    <Wrapper top={top} headerPinned={headerPinned} {...props}>
      {children}
    </Wrapper>
  );
}

StickyPanel.defaultProps = {
  top: null,
};

StickyPanel.propTypes = {
  children: PropTypes.node.isRequired,
  top: PropTypes.number,
};

export default StickyPanel;
