import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import Icon from 'components/ContentIcon';
import Title from 'components/Heading/SubHeading2';
import Description from 'components/Paragraph/Small';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const Item = styled.li`
  padding: 0;
  text-align: center;

  & + & {
    margin-top: 30px;
  }
  svg {
    width: 89px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    text-align: left;
    padding: 0;
    margin: 0;
    display: table;

    svg {
      display: table-cell;
    }
  }
`;

const Content = styled.article`
  @media screen and (min-width: ${breakpoints.md}) {
    padding: 0 0 0 30px;
    display: table-cell;
    vertical-align: middle;
  }
`;

function ItemList({ className, items }) {
  return (
    <Wrapper className={className}>
      {items.map(item => (
        <Item key={item.title || item.description}>
          <Icon type={item.iconType} />
          <Content>
            <Title>{item.title}</Title>
            {item.description && (
              <Description as={Markdown} source={item.description} />
            )}
          </Content>
        </Item>
      ))}
    </Wrapper>
  );
}

ItemList.defaultProps = {
  className: null,
  items: [],
};

ItemList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      iconType: Icon.propTypes.type,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ),
};

export default ItemList;
